import { Helmet } from "react-helmet";

const DocHeader = ({
  title = "",
  description = "description",
  content = "AI Entry Content Management System",
}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name={description} content={content} />
        <title>ai entry | {title}</title>
        <link rel="canonical" href={process.env.REACT_APP_URL} />
      </Helmet>
    </>
  );
};

export default DocHeader;
