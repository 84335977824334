import {
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { lang } from "utils/translations/globalLang";
import LogoutIcon from "@mui/icons-material/Logout";

import EN from "assets/images/auth/eng_logo.png";
import JP from "assets/images/auth/japan_logo.png";
import Logo from "components/_generics/logo/Logo";
import MobileDrawer from "components/_generics/mobileDrawer";
import React, { useState } from "react";
// import JP from "assets/images/auth/1.jpg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import defaultImage from "assets/images/default_profile.svg";
import HeaderWrapper from "components/_generics/HeaderWrapper";
import { logoutUser } from "endpoints/auth/authEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { setAccessToken } from "reducer/auth/authSlice";
import { setLang } from "reducer/lang/langSlice";

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const langType = useSelector((state) => state.lang.lang);
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElLang, setAnchorElLang] = React.useState(null);
  const openLang = Boolean(anchorElLang);

  const handleLogout = (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    logoutUser()
      .then((response) => {
        dispatch(setAccessToken(""));
        profileClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((response) => {
        setIsSubmitLoading(false);
        navigate("/login");
      });
  };
  const handleClick = (event) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLang(null);
  };

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const profileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <HeaderWrapper
        sx={{
          padding: `${isNonMobileScreens ? "1px 1%" : "1px 5%"}`,
          position: "sticky",
          top: "0",
          zIndex: 40,
          boxShadow: 2,
        }}
      >
        {isNonMobileScreens && accessToken && <Stack></Stack>}
        {!isNonMobileScreens && !accessToken && <Logo />}
        {isNonMobileScreens && !accessToken && <Logo />}
        {/* MOBILE NAV  */}
        {accessToken && !isNonMobileScreens && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MobileDrawer handleLogout={handleLogout} />
          </Box>
        )}
        {/* RIGHT ICONS  */}
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {/* LANGUAGE SELECT  */}
          <Box>
            <IconButton
              onClick={handleClick}
              sx={{ ml: 2, mt: 1 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              fontSize="small"
            >
              {langType === "EN" && <img width={30} src={EN} alt="Profile" />}
              {langType === "JP" && (
                <img
                  width={30}
                  style={{ border: "1px solid gray", borderRadius: 100 }}
                  src={JP}
                  alt="Profile"
                />
              )}
            </IconButton>
            <Menu
              anchorEl={anchorElLang}
              id="account-menu"
              open={openLang}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem sx={{ gap: 1 }} onClick={() => dispatch(setLang("EN"))}>
                <img style={{ width: "20px" }} src={EN} alt="english" />
                <span style={{ color: theme.palette.primary.main }}>
                  English
                </span>
              </MenuItem>
              <MenuItem sx={{ gap: 1 }} onClick={() => dispatch(setLang("JP"))}>
                <img
                  style={{
                    width: "20px",
                    border: "1px solid gray",
                    borderRadius: 100,
                  }}
                  src={JP}
                  alt="japanese"
                />
                <span style={{ color: theme.palette.primary.main }}>
                  Japanese
                </span>
              </MenuItem>
            </Menu>
          </Box>
          {/* PROFILE PICTURE  */}
          {accessToken && (
            <Box>
              <IconButton onClick={profileClick} backgroundColor="white">
                <img
                  src={localStorage.getItem("profile_picture") || defaultImage}
                  alt="profile picture"
                  width={40}
                />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={profileClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{ mt: 0.5 }}
              >
                <Box sx={{ minWidth: 130 }}>
                  <Link underline={"none"} component={NavLink} to={"profile"}>
                    <Typography
                      variant="h6"
                      sx={{
                        p: 1.5,
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: palette.primary.light,
                          color: "white",
                        },
                      }}
                      onClick={profileClose}
                    >
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <AccountCircleIcon /> {lang[langType].profile}
                      </Stack>
                    </Typography>
                  </Link>
                  <Typography
                    variant="h6"
                    sx={{
                      p: 1.5,
                      cursor: "pointer",
                      color: palette.primary.main,

                      "&:hover": {
                        backgroundColor: palette.primary.light,
                        color: "white",
                      },
                    }}
                    onClick={(e) => handleLogout(e)}
                  >
                    {!isSubmitLoading ? (
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <LogoutIcon /> {lang[langType].logout}
                      </Stack>
                    ) : (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <SyncLoader
                          size={"10px"}
                          style={{ paddingY: "1px", textAlign: "center" }}
                          color={theme.palette.primary.main}
                        />
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Popover>
            </Box>
          )}{" "}
          {!accessToken && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                component={NavLink}
                to={"/login"}
                sx={{ textTransform: "none", fontSize: 16 }}
              >
                {lang[langType].login}
              </Button>
            </Box>
          )}
        </Stack>
      </HeaderWrapper>
      {/* <Divider
        sx={{
          width: "90%",
          mx: "auto",
          fontSize: 10,
          background: theme.palette.primary.main,
        }}
      /> */}
    </>
  );
};

export default Header;
