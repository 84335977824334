import moment from "moment";
import { numberRegex } from "./regex";

// True if empty, false if there is a value
export function checkIfEmpty(data) {
  let valid = true;

  if (data) {
    if (typeof data === "string") {
      valid = data.trim().length === 0;
    } else {
      valid = data.length === 0;
    }
  }

  return valid;
}

export function formValidate(errors) {
  let valid = true;

  Object.values(errors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  console.log("Ovject.value", valid);

  return valid;
}
export function formTouch(data) {
  let valid = true;

  Object.values(data).forEach((val) => {
    if (val) {
      ((typeof val === "string" && val.trim().length === 0) ||
        val.length === 0) &&
        (valid = false);
    } else {
      valid = false;
    }

    return valid;
  });
  return valid;
}

export function prefectureFinder(list, prefcode, lang) {
  let prefName = "";
  list.forEach((pref) => {
    if (Number(pref.value) === Number(prefcode)) {
      prefName = pref[lang];
    }
  });
  return prefName;
}

export function checkIfEmptyNotWhitespace(data) {
  let valid = true;

  if (data) {
    valid = data.length === 0;
  }

  return valid;
}

export function checkIfWhitespace(data) {
  let valid = true;

  if (data && typeof data === "string") {
    valid = data.trim().length === 0;
  }

  return valid;
}

export function numberStringValidator(string) {
  let valid = true;
  for (let x = 0; x < string.length; x++) {
    const c = string.charAt(x);
    valid = !numberRegex.test(c) ? false : valid;
  }
  return valid;
}

export function dateYearMonthDayOnly(value, addOne = true) {
  const dateObj = value instanceof Date ? value : new Date(value);
  const one = addOne ? 1 : 0;
  return `${dateObj.getFullYear()}-${
    dateObj.getMonth() + one
  } - ${dateObj.getDate()}`;
}

export function promiseSetter(response) {
  let params = {
    success: true,
    message: "",
    data: [],
  };
  response.forEach((response) => {
    if (response.code !== 200) {
      return (params = {
        ...params,
        success: false,
        message: response.message,
      });
    } else {
      params = {
        ...params,
        success: true,
      };
      params.data.push(response);
    }
  });

  return params;
}

// Returns first name and last name as single string
export function returnFullName(
  fname = "",
  lname = "",
  reverse = false,
  returnValue = null
) {
  if (reverse) {
    return fname || lname ? `${lname} ${fname}` : returnValue;
  }

  return fname || lname ? `${fname} ${lname}` : returnValue;
}

export function getTimeFromDate(data) {
  return moment(data, "HH:mm:ss").format("HH:mm:ss");
}

export function setTimeForDate(time_string, paramsDate = null) {
  const day = !paramsDate ? new Date() : paramsDate;
  const time = String(time_string).split(":");
  const date = String(dateYearMonthDayOnly(day, false)).split("-");
  return new Date(date[0], date[1], date[2], time[0], time[1], time[2]);
}

export function getTimeDifference(timeIn, timeOut) {
  if (!checkIfEmpty(timeIn) && !checkIfEmpty(timeOut)) {
    const format = "YYYY/MM/DD HH:mm:ss a";
    const difference = moment(timeOut, format).diff(moment(timeIn, format));
    const result = moment.duration(difference);
    return moment.utc(result.as("milliseconds")).format("HH:mm");
  }
  return null;
}

export function handleErrorMessage(data, prevError, errorMessage) {
  return formTouch(data) ? prevError : errorMessage;
}

export function convertBytesToGigabytes(bytes) {
  // formula to convert bytes to gigabytes
  const gigabytes = bytes / 1e9;

  return gigabytes;
}

export function convertBytesToMegabytes(bytes) {
  // formula to convert bytes to megabytes
  const megabytes = bytes / 1e6;

  return megabytes;
}

export function convertBytesToKilobytes(bytes) {
  // formula to convert bytes to kilobytes
  const kilobytes = parseFloat(bytes) / 1000;

  return kilobytes;
}

export function stringToArray(array) {
  return !checkIfEmpty(array) ? array.split(", ") : [];
}

export function isSameDate(valueOne, valueTwo) {
  const dateObjOne = valueOne instanceof Date ? valueOne : new Date(valueOne);
  const dateObjTwo = valueTwo instanceof Date ? valueTwo : new Date(valueTwo);

  if (dateObjOne.getDate() === dateObjTwo.getDate()) {
    return true;
  }

  return false;
}
